import React from "react";

import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Alert from "@mui/material/Alert";

const Pro = () => {
  return (
    <Layout>
      <Seo title="Pro features" />
      <h1>Pro features</h1>
      <Alert severity="warning">Comming soon...</Alert>
      <br />
      <h3>
        <strong>Multiple</strong> social media <strong>accounts</strong>
      </h3>
      <h3>
        <strong>Individual times</strong> for the e-mail
      </h3>
    </Layout>
  );
};

export default Pro;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
